import { TermsSection } from '../types'

interface ParagraphTemplateProps {
	title: string
	sections: TermsSection[]
}

const ParagraphTemplate = ({ title, sections }: ParagraphTemplateProps) => {
	return (
		<div className="space-y-3 break-words">
			<h1 className="text-typography-primary">{title}</h1>
			{sections.map((section: TermsSection, index: number) => (
				<div key={index} className="body-2-normal flex flex-col space-y-3 text-typography-tertiary">
					{typeof section.content === 'string' ? (
						<div>
							{section.numbering ? `${section.numbering} ${section.content}` : section.content}
						</div>
					) : (
						section.content
					)}
					{!!section.subTermsSection &&
						section.subTermsSection.map((subSection: TermsSection, subIndex: number) => (
							<div key={subIndex} className="pl-4">
								{typeof subSection.content === 'string' ? (
									<div>
										{subSection.numbering
											? `${subSection.numbering} ${subSection.content}`
											: subSection.content}
									</div>
								) : (
									subSection.content
								)}
							</div>
						))}
				</div>
			))}
		</div>
	)
}

export default ParagraphTemplate
