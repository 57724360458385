import ScreenContainer from 'src/_shared/components/ScreenContainer'

import Definition from './components/Definition'
import ParagraphTemplate from './components/ParagraphTemplate'
import { DEFINITION_LIST, UPDATED_DATE, TERMS_SECTION_COLLATED } from './constants'
import { TermsDefinition, TermsParagraph } from './types'

const TermsConditionsScreen = () => {
	return (
		<ScreenContainer contentViewProps={{ className: 'px-5 py-6' }} hideBottomBar>
			{/* Header Section */}
			<div className="mb-5 space-y-3">
				<h1 className="flex justify-start text-typography-primary">Terms and Privacy Notice</h1>
				<h1 className="text-typography-primary">Terms of Use</h1>
				<span className="body-3-medium italic text-typography-primary">{`Updated Date: ${UPDATED_DATE}`}</span>
			</div>
			{/* Definition Section */}
			<div className="mb-5 space-y-3">
				<h1 className="text-typography-primary">Definitions</h1>
				{DEFINITION_LIST.map(({ header, body }: TermsDefinition, index: number) => {
					return <Definition key={index} header={header} body={body} />
				})}
			</div>
			{/* Main Sections */}
			<div className="space-y-3">
				{TERMS_SECTION_COLLATED.map((paragraph: TermsParagraph, index: number) => {
					return (
						<ParagraphTemplate key={index} title={paragraph.title} sections={paragraph.sections} />
					)
				})}
			</div>
		</ScreenContainer>
	)
}

export default TermsConditionsScreen
