import TelegramIcon from 'src/_shared/components/_icons/TelegramIcon'
import WhatsAppIcon from 'src/_shared/components/_icons/WhatsAppIcon'
import { CONTACT_US_METHOD } from 'src/_shared/constants/env'

import { Section } from './types'

export const SEARCH_INPUT_DEBOUNCE = 125

// Note: Best to get these values in Strapi in the future so that re-deployment does not have to be done.
export const CONTACT_US_BUTTON_PREFIX_ICON = ((): JSX.Element | null => {
	const iconType = CONTACT_US_METHOD
	switch (iconType) {
		case 'telegram':
			return <TelegramIcon className="mr-2 w-5" />
		case 'whatsapp':
			return <WhatsAppIcon className="mr-2 w-5" />
		default:
			return null
	}
})()

const CONTACT_US_TEXT = (() => {
	switch (CONTACT_US_METHOD) {
		case 'telegram':
			return 'Telegram'
		case 'whatsapp':
			return 'WhatsApp'
		default:
			return null
	}
})()

const CHARGING_HOW_TO_CHARGE_INSTRUCTIONS = [
	'After you have safely parked in the lot at your desired charger, please ensure your mobile device has an active stable internet connection.',
	'In the web app in either list view or map view, select the charger you wish to charge from. Some chargers may allow you to scan the charger’s QR code from the web app to identify the charger.',
	"Please ensure both the charger gun and your electric vehicle's charging socket is clear of any foreign objects. Connect the charger gun to your electric vehicle, and ensure the the connection is secure.",
	'Once prompted, press Next.',
	'For non-subscribed users, please ensure the payment method used is accurate with sufficient value. Press Next when ready.',
	'Your electric vehicle should start charging.',
	'To stop charging, "Slide to Stop Charging" as indicated on the screen before detaching the charger gun from your electric vehicle. Please consult your electric vehicle’s instruction manual for instructions for safely removing the charger gun from the vehicle.',
	'Once the web app has detected the charger gun has been removed from your electric vehicle, the cost of the charge session will be deducted from the pre-authentication amount, and you will be refunded the balance. A receipt page will be displayed on the web app for your reference.'
]

const CHARGING_HOW_TO_TROUBLESHOOT_INSTRUCTIONS = [
	'Check the Connection: Ensure that the charging cable is properly connected to both the EV and the charging station. Some charging stations require a specific orientation or a firm push to establish a connection.',
	'Check your internet connection: Please ensure your device has a stable internet connection.',
	'Restart the Session: Attempt to initiate the charging session again through the web app or refresh the web app. A temporary glitch could have prevented the session from starting.',
	'Check for Notifications: Look for any error messages or notifications in the web app or charger interface. These messages can provide specific instructions or indicate if the station is out of service.',
	'Use an Alternative Charger: If possible, try connecting to a different charging point or station. This can help determine if the issue is with the charging station or your vehicle.',
	...(CONTACT_US_TEXT
		? [
				"Contact Support: If the above steps do not resolve the issue, please contact Voltality's customer support for assistance using the contact button located at the bottom of the FAQ page. Provide them with the charging station ID, your vehicle model, and a description of the issue."
			]
		: [])
]

export const SECTIONS: Section[] = [
	{
		sectionName: 'General',
		questions: [
			{
				question: 'What should I do if I forgot my login password?',
				answer:
					'Use the "Forgot Password" feature on the login page to reset your password. You may choose to reset your password via mobile number or email. Follow the instructions sent to your mobile number or email to set a new password.'
			},
			{
				question: "What should I do if the web app doesn't show nearby charging stations?",
				answer:
					"Ensure your device's location services are enabled and that the web app has permission to access your location. If the issue persists, try refreshing the web app."
			},
			{
				question: 'Why am I unable to find my location within the web app?',
				answer:
					'Please ensure you have enabled location services on both your phone and the browser used.'
			},
			...(CONTACT_US_TEXT
				? [
						{
							question: 'How can I report a problem with a charging station through the web app?',
							answer: `Please inform us of any faulty chargers using the ${CONTACT_US_TEXT} contact button at the bottom of the FAQ page. Please include relevant information such as the location, the charger identifier, and the defect.`
						}
						// FUTURE TODO: Pending Contact Number.
						// {
						//	question: 'Who can I contact for more help with regard to electric vehicle charging?',
						//	answer:
						//		`You may reach out to our representative via the contact button located at the bottom of the FAQ page. Alternatively, you may contact us at 0000 0000 for further assistance.`
						// }
					]
				: [])
		]
	},
	{
		sectionName: 'Charging',
		questions: [
			{
				question: 'How do I start charging my electric vehicle?',
				answer: CHARGING_HOW_TO_CHARGE_INSTRUCTIONS.join(' '),
				answerElement: (
					<ol className="list-decimal ps-4">
						{CHARGING_HOW_TO_CHARGE_INSTRUCTIONS.map((instruction, index): JSX.Element => {
							return (
								// Note: `mb-2` applies to all `li` elements but the last one.
								<li key={index} className="[&:not(:last-child)]:mb-2">
									{instruction}
								</li>
							)
						})}
					</ol>
				)
			},
			{
				question: 'What can I do if the charging session does not start?',
				answer: [
					'If your charging session does not start, please follow these steps to troubleshoot the issue:',
					...CHARGING_HOW_TO_TROUBLESHOOT_INSTRUCTIONS
				].join(' '),
				answerElement: (
					<>
						<p className="mb-3">
							If your charging session does not start, please follow these steps to troubleshoot the
							issue:
						</p>
						<ol className="list-decimal ps-4">
							{CHARGING_HOW_TO_TROUBLESHOOT_INSTRUCTIONS.map((instruction, index): JSX.Element => {
								return (
									// Note: `mb-2` applies to all `li` elements but the last one.
									<li key={index} className="[&:not(:last-child)]:mb-2">
										{instruction}
									</li>
								)
							})}
						</ol>
					</>
				)
			},
			{
				question: 'The charging session stopped unexpectedly. What can I do?  ',
				answer:
					'Check the web app for any error messages or notifications related to the session. Ensure that the charging gun is properly connected to your vehicle. If the problem persists, end the current session in the web app and use a different charger.'
			},
			{
				question:
					'My vehicle is not connecting to the charger when the charger gun is attached correctly. What should I do?',
				answer:
					'Older electric vehicles may occasionally encounter this issue. We recommend switching to another available charger under a different brand, and attempt to start a new charge session. Alternatively, you may attempt to disconnect the charger from your electric vehicle, reconnect the charger gun, and attempt to start the session again. '
			},
			{
				question: 'I am unable to stop the charge session from the web app. What should I do?',
				answer:
					'In the unlikely event where the web app is unable to stop the charge session, please stop the charge session by conducting a vehicle stop. Please consult your electric vehicle’s instruction manual to do so. Only conduct an emergency stop if attempts to stop via the web app and vehicle stop fails. Please follow the instructions on the charger to conduct an emergency stop. Remove the charger gun from your electric vehicle when it is safe to do so.' +
					(CONTACT_US_TEXT
						? ' Please notify us through the contact button located at the bottom of the FAQ page.'
						: '')
			}
		]
	},
	{
		sectionName: 'Payment and Billing',
		questions: [
			{
				question: 'Why was I invoiced at the start of a charge session?',
				answer:
					'Pre-authentication is a security measure implemented to ensure that only authorised users can initiate charging sessions at our stations. Before starting a charge, the system verifies your identity and confirms that your account is in good standing. This process involves checking your registered payment method and ensuring there are sufficient funds or credit available to cover the cost of the charging session. The balance of the pre-authenticated amount will be refunded back to you after deducting the cost of your charge session.'
			},
			{
				question: 'How do I resolve a payment failure in the web app?',
				answer:
					"Check if your payment method is valid and has sufficient funds. Ensure that you've entered the correct payment details in the web app. If everything seems correct, please try the payment again later or contact your bank for further assistance."
			},
			{
				question: 'What are "idle fees" and why was I billed for it?',
				answer:
					'Idle fees are charges applied to your account when your vehicle remains connected to the charging station beyond the grace period allocated after the completion of the charging session. These fees are designed to encourage the timely turnover of charging spots, ensuring that as many users as possible can access our charging stations throughout the day. Idle fee rates may vary at different chargers.' +
					(CONTACT_US_TEXT
						? ` If there was a mistake with the billing of your session, please contact us via the ${CONTACT_US_TEXT} button at the bottom of the FAQ page.`
						: '')
			},
			{
				question: 'How can I update my payment method in the web app?',
				answer:
					'Navigate to the account page and select "My payment card details". Select "Replace Card" to update your payment method. Please ensure all changes are saved before exiting the page'
			},
			{
				question: 'Where can I view my past charge sessions?',
				answer:
					'You may review up to 50 of your past charging sessions in Charging History. Navigate to Charging History using the navigation bar located at the bottom of your screen and select on any of the past 50 charging sessions for your review.'
			},
			{
				question: 'Why was I billed more than the amount of energy charged?',
				answer:
					'A minimum transaction fee will be applied for all successful sessions. This will only apply if the total cost of the session is below the minimum transaction fee. Please note that the minimum transaction fee does not add to the total cost of your session. The minimum transaction fee will differ depending on the country of the charger used.'
			}
		]
	}
]
