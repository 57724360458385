import { ReactNode, memo } from 'react'
import { classNames } from 'src/_shared/utils/elements'

interface LabelValueListItemProps {
	label: string
	containerClassName?: string
	labelClassName?: string
	value: ReactNode
}

const LabelValueListItem = ({
	label,
	labelClassName,
	value,
	containerClassName
}: LabelValueListItemProps) => {
	return (
		<div className={classNames('mb-4 flex items-center justify-between', containerClassName)}>
			<div>
				<span className={classNames('body-2-normal text-typography-tertiary', labelClassName)}>
					{label}
				</span>
			</div>
			<div className="text-right">
				<span className="body-2-semibold text-typography-primary">{value}</span>
			</div>
		</div>
	)
}

const MemoisedLabelValueListItem = memo(LabelValueListItem)

export default MemoisedLabelValueListItem
