import { useQueryClient } from '@tanstack/react-query'
import { useCallback, useEffect, useState } from 'react'
import { ScreenRoutePath } from 'src/App/router/hooks'
import Button from 'src/_shared/components/Button'
import Modal from 'src/_shared/components/Modal'
import ModalCard from 'src/_shared/components/Modal/components/ModalCard'
import PaymentMethodsList from 'src/_shared/components/PaymentMethodsList'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import TopBarButton from 'src/_shared/components/ScreenContainer/components/TopBarButton'
import Spinner from 'src/_shared/components/Spinner'
import CheckCircleFilledIcon from 'src/_shared/components/_icons/CheckCircleFilledIcon'
import CheckCircleIcon from 'src/_shared/components/_icons/CheckCircleIcon'
import CheckIcon from 'src/_shared/components/_icons/CheckIcon'
import ErrorFilledIcon from 'src/_shared/components/_icons/ErrorFilledIcon'
import TrashIcon from 'src/_shared/components/_icons/TrashIcon'
import { useDeletePaymentMethodMutation } from 'src/_shared/mutations/payments'
import {
	PaymentsQueryKey,
	ROOT_PAYMENTS_QUERY_KEY,
	usePaymentMethodsQuery
} from 'src/_shared/queries/payments'
import { PaymentMethod } from 'src/_shared/types/payments'
import { classNames } from 'src/_shared/utils/elements'
import { delay } from 'src/_shared/utils/time'

const AccountPaymentMethodsScreen = (): JSX.Element => {
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod | null>(null)

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null)

	const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<PaymentMethod | null>(null)

	const [isEditing, setIsEditing] = useState<boolean>(false)

	const queryClient = useQueryClient()

	const { data: paymentMethods = [], isLoading: isPaymentMethodsLoading } = usePaymentMethodsQuery()

	const {
		isError: isDeletePaymentMethodError,
		isIdle: isDeletePaymentMethodIdle,
		isPending: isDeletePaymentMethodPending,
		isSuccess: isDeletePaymentMethodSuccess,
		mutateAsync: deletePaymentMethod,
		reset: resetDeletePaymentMethodMutation
	} = useDeletePaymentMethodMutation()

	const toggleIsEditing = (): void => {
		setIsEditing(!isEditing)
	}

	const handleDeletePaymentMethod = (): void => {
		if (paymentMethodToDelete?._id) {
			void deletePaymentMethod(
				{ paymentMethodId: paymentMethodToDelete._id },
				{
					onSettled: (): void => {
						const handleSettled = async (): Promise<void> => {
							await delay(2500) // Delay briefly show to the user whether or not the payment method was deleted.
							await queryClient.invalidateQueries({
								queryKey: [ROOT_PAYMENTS_QUERY_KEY, PaymentsQueryKey.PaymentMethods]
							})
							resetDeletePaymentMethodMutation()
							setPaymentMethodToDelete(null)
						}
						void handleSettled()
					}
				}
			)
		}
	}

	const handleDeletePaymentMethodModalClose = (): void => {
		if (isDeletePaymentMethodIdle) {
			setPaymentMethodToDelete(null)
		}
	}

	const listItemRightRender = useCallback(
		(paymentMethod: PaymentMethod): JSX.Element => {
			const isDefaultPaymentMethod = paymentMethod._id === defaultPaymentMethod?._id

			const isSelectedPaymentMethod = paymentMethod._id === selectedPaymentMethod?._id

			if (isEditing) {
				return (
					<button
						onClick={(): void => {
							setPaymentMethodToDelete(paymentMethod)
						}}
					>
						<span className="body-1-medium text-error-300">Delete</span>
					</button>
				)
			}
			// Currently assumes that we only have 1 payment method, hence it's also the default payment.
			// FUTURE TODO: Handle multiple payment methods.
			return (
				<div className="flex items-center space-x-2">
					{isDefaultPaymentMethod ? (
						<div className="flex items-center rounded-full border border-primary-800 bg-primary-800 bg-opacity-10 px-2 py-0.5">
							<span className="body-1-normal text-primary-800">Default</span>
						</div>
					) : (
						<span className="body-1-normal text-typography-secondary">Set as default</span>
					)}
					{isSelectedPaymentMethod ? (
						<CheckCircleIcon className="w-6 text-success-400" />
					) : (
						<div className="h-5 w-5 rounded-full border border-primary-400/30" />
					)}
				</div>
			)
		},
		[defaultPaymentMethod, selectedPaymentMethod, isEditing]
	)

	/**
	 * If all payment methods have been deleted, revert `isEditing` back to `false`.
	 */
	useEffect((): void => {
		if (isEditing && paymentMethods.length === 0) {
			setIsEditing(false)
		}
	}, [isEditing, paymentMethods.length])

	useEffect((): void => {
		if (!defaultPaymentMethod && !selectedPaymentMethod && paymentMethods[0]) {
			setDefaultPaymentMethod(paymentMethods[0])
			setSelectedPaymentMethod(paymentMethods[0])
		}
	}, [defaultPaymentMethod, selectedPaymentMethod, paymentMethods])

	return (
		<ScreenContainer
			contentViewProps={{
				className: classNames(
					'px-5 py-6',
					isPaymentMethodsLoading ? 'items-center justify-center' : null
				)
			}}
			topBarProps={{
				centerRender: <h1>Payment Methods</h1>,
				rightRender:
					paymentMethods.length > 0 ? (
						<TopBarButton onClick={toggleIsEditing}>
							{isEditing ? <CheckIcon className="h-4" /> : <TrashIcon className="h-4" />}
						</TopBarButton>
					) : null
			}}
			hideBottomBar
		>
			{isPaymentMethodsLoading ? (
				<Spinner />
			) : (
				<>
					{/* Payment Methods List */}
					<PaymentMethodsList
						className="mb-5"
						paymentMethods={paymentMethods}
						listItemRightRender={listItemRightRender}
					/>
					{/* Add/Replace Card Button */}
					{!isEditing && (
						<div>
							<Button
								variant="dashed"
								className="w-full"
								linkProps={{ to: ScreenRoutePath.AccountPaymentMethodsNew }}
							>
								+ {paymentMethods.length === 0 ? 'Add' : 'Replace'} Card
							</Button>
						</div>
					)}
				</>
			)}
			{/* Delete Payment Method Modal */}
			<Modal open={!!paymentMethodToDelete} onClose={handleDeletePaymentMethodModalClose}>
				<ModalCard className="flex flex-col items-center !pb-10 !pt-8">
					{((): JSX.Element => {
						if (isDeletePaymentMethodError) {
							return (
								<>
									<ErrorFilledIcon className="mb-4 h-12 w-12 text-error-300" />
									<h1 className="mb-2 text-center">Error</h1>
									<p className="body-2-normal text-center">
										Your payment method details were not removed.
									</p>
								</>
							)
						} else if (isDeletePaymentMethodSuccess) {
							return (
								<>
									<CheckCircleFilledIcon className="mb-4 h-12 w-12 text-success-400" />
									<h1 className="mb-2 text-center">Success</h1>
									<p className="body-2-normal text-center">
										Your payment method details have been removed.
									</p>
								</>
							)
						}
						return (
							<>
								<h1 className="mb-2 text-center">Notice</h1>
								<p className="body-2-normal mb-8 text-center">
									Are you sure you want to delete this card?
								</p>
								<div className="flex w-full flex-col space-y-4">
									<Button
										variant="primary"
										disabled={isDeletePaymentMethodPending}
										loading={isDeletePaymentMethodPending}
										onClick={handleDeletePaymentMethod}
									>
										Confirm
									</Button>
									<Button
										variant="secondary"
										disabled={isDeletePaymentMethodPending}
										onClick={handleDeletePaymentMethodModalClose}
									>
										Cancel
									</Button>
								</div>
							</>
						)
					})()}
				</ModalCard>
			</Modal>
		</ScreenContainer>
	)
}

export default AccountPaymentMethodsScreen
