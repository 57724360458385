import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import ReceiptDetails from 'src/_shared/components/ReceiptDetails'

import { ChargerScreenViewItemKey } from '../enums'
import { useChargerDetails } from '../hooks/useChargerDetails'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps } from '../types'
import Footer from './Footer'

type ReceiptViewProps = ChargerScreenCommonViewProps

const ReceiptView = ({ routeParams, onNext: handleNext }: ReceiptViewProps): JSX.Element => {
	const intl = useIntl()

	const { connector } = useChargerDetails(routeParams)

	const { session, isUserChargingSession, chargerSessionDetailsQueryStatus } =
		useChargerSessionDetails(routeParams)

	const isChargerSessionDetailsPending = chargerSessionDetailsQueryStatus === 'pending'

	/**
	 * Debugging
	 */
	useEffect((): void => {
		console.debug(
			`[${ChargerScreenViewItemKey.ReceiptView}]\n\n`,
			`Connector Status: ${connector?.status ? connector.status : '-'}\n\n`,
			`Session Status: ${session?.status ? session.status : '-'}\n\n`,
			`Is User's Charging Session: ${isUserChargingSession}\n\n`,
			'Connector Data:\n',
			connector,
			'\n\n',
			'Session Data:\n',
			session
		)
	}, [connector, isUserChargingSession, session])

	return (
		<>
			<div className="flex-grow">{session && <ReceiptDetails chargingSession={session} />}</div>
			<Footer
				buttonProps={{
					children: intl.formatMessage({
						id: 'ReceiptView.ButtonTextClose',
						defaultMessage: 'Close'
					}),
					className: 'w-full',
					disabled: isChargerSessionDetailsPending,
					variant: 'ghost',
					onClick: handleNext
				}}
			/>
		</>
	)
}

export default ReceiptView
