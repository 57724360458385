import { ScreenRoutePath, useRouterNavigate } from 'src/App/router/hooks'
import Charge from 'src/_shared/_old/assets/svgs/Charge'
import { FILTER_BAR_HEIGHT, TOPBAR_HEIGHT } from 'src/_shared/_old/enums'
import { ChargerScreenRouteParams } from 'src/screens/ChargerScreen/types'

interface LiveSessionProps {
	chargerRouterDetails: ChargerScreenRouteParams
}

const LiveSession = ({ chargerRouterDetails }: LiveSessionProps): JSX.Element => {
	const navigate = useRouterNavigate()

	const { cpoEntityCode, locationUid, evseUid, connectorUid } = chargerRouterDetails

	const onClickCurrentChargingHandler: () => void = () => {
		navigate([ScreenRoutePath.Charger, cpoEntityCode, locationUid, evseUid, connectorUid])
	}
	return (
		<div
			className="absolute top-1 z-10 h-fit w-[95%] rounded-[2rem] bg-primary-800 bg-button-primary-gradient px-4 py-3"
			style={{
				marginTop: TOPBAR_HEIGHT + FILTER_BAR_HEIGHT
			}}
		>
			<button
				className="flex w-full items-center justify-between"
				onClick={onClickCurrentChargingHandler}
			>
				{/* Live Session Title and Lightning Bolt */}
				<div className="flex items-center space-x-2">
					<Charge className="h-4 w-4 text-white" />
					<span className="body-1-semibold text-white">Live Session</span>
				</div>
				{/* Live Session Text */}
				<span className="body-1-normal mr-2 text-white">Click to view charging session</span>
			</button>
		</div>
	)
}

export default LiveSession
