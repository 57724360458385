import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo } from 'react'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import Spinner from 'src/_shared/components/Spinner'
import { ROOT_SESSIONS_QUERY_KEY, SessionsQueryKey } from 'src/_shared/queries/sessions'
import { OmniSession } from 'src/_shared/types/omni'
import {
	useChargingSessionInfiniteQuery,
	ChargingSessionPage
} from 'src/screens/HistoryScreen/queries'

import HistoryListController from './components/HistoryListController'
import HistoryLoader from './components/HistoryLoader'
import { FormattedSession } from './types'
import { sessionFormatter } from './utils'

/* Toggle this if you would like to control the total amount of sessions (cumulative sessions in each page) */
const MAX_SESSION_PER_PAGE = 15

const HistoryScreen = (): JSX.Element => {
	const {
		data,
		isLoading: chargingSessionsLoading,
		fetchNextPage,
		isFetchingNextPage,
		hasNextPage
	} = useChargingSessionInfiniteQuery({
		maxNumber: MAX_SESSION_PER_PAGE
	})

	const chargingSessionPages: ChargingSessionPage[] | undefined = data?.pages

	// logic to cache all fetched sessions so receipt can be displayed without fetching again
	const queryClient = useQueryClient()
	useEffect(() => {
		if (
			Array.isArray(chargingSessionPages) &&
			chargingSessionPages[chargingSessionPages.length - 1]
		) {
			// Only cache the last page, which always contains new data
			chargingSessionPages[chargingSessionPages.length - 1].data.forEach(
				(chargingSession: OmniSession) => {
					queryClient.setQueryData(
						[ROOT_SESSIONS_QUERY_KEY, SessionsQueryKey.Session, { sessionId: chargingSession._id }],
						chargingSession
					)
				}
			)
		}
	}, [chargingSessionPages, queryClient])

	// logic to format charging sessions, re-computation happens at every page change
	const formattedChargingSessions: FormattedSession[] = useMemo(() => {
		if (!chargingSessionPages || !Array.isArray(chargingSessionPages)) {
			return []
		}
		const newData: OmniSession[] = chargingSessionPages.reduce(
			(acc: OmniSession[], chargingSessionPage: ChargingSessionPage) => {
				return [...acc, ...chargingSessionPage.data]
			},
			[]
		)
		return sessionFormatter(newData)
	}, [chargingSessionPages])

	const userHaveNoChargingSessions: boolean =
		Array.isArray(formattedChargingSessions) &&
		formattedChargingSessions.length === 0 &&
		!hasNextPage &&
		!chargingSessionsLoading

	return (
		<ScreenContainer
			contentViewProps={{
				className:
					chargingSessionsLoading || userHaveNoChargingSessions
						? 'justify-around items-center'
						: undefined
			}}
			topBarProps={{
				centerRender: <h1>Charging History</h1>
			}}
		>
			{userHaveNoChargingSessions ? (
				<div className="flex h-full w-full flex-grow items-center justify-center">
					<p className="body-1-normal text-center text-typography-tertiary">
						You have no charging sessions history
					</p>
				</div>
			) : chargingSessionsLoading ? (
				<div className="flex flex-grow flex-col items-center justify-center">
					<Spinner />
				</div>
			) : (
				<div className="w-full">
					<HistoryListController
						formattedChargingSessions={formattedChargingSessions}
						onFetchNextPageCallback={hasNextPage ? fetchNextPage : undefined}
					/>
					{isFetchingNextPage && <HistoryLoader description="Loading..." />}
					{!hasNextPage && (
						<HistoryLoader description="You have reached the end of your charging history" />
					)}
				</div>
			)}
		</ScreenContainer>
	)
}

export default HistoryScreen
