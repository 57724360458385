import { ReactNode, memo } from 'react'

import LabelValueListItem from './LabelValueListItem'

interface LabelValueListProps {
	className?: string
	labelValueList: {
		label: string
		labelClassName?: string
		containerClassName?: string
		value: ReactNode
	}[]
	title: JSX.Element | string
}

const LabelValueList = ({ className, title, labelValueList }: LabelValueListProps): JSX.Element => {
	return (
		<div className={className}>
			<div className="mb-4">
				<span className="body-3-semibold text-typography-primary">{title}</span>
			</div>
			{labelValueList.map(
				({ label, labelClassName, value, containerClassName }, index): JSX.Element => (
					<LabelValueListItem
						key={index}
						label={label}
						labelClassName={labelClassName}
						value={value}
						containerClassName={containerClassName}
					/>
				)
			)}
		</div>
	)
}

const MemoisedLabelValueList = memo(LabelValueList)

export default MemoisedLabelValueList
