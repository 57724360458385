export enum OmniSessionChargingPeriodDimensionType {
	Energy = 'ENERGY',
	ParkingTime = 'PARKING_TIME',
	Power = 'POWER',
	ReservationTime = 'RESERVATION_TIME',
	StateOfCharge = 'STATE_OF_CHARGE',
	Time = 'TIME'
}

export enum OmniSessionNotificationType {
	End = 'END',
	GraceInterval = 'GRACE_INTERVAL',
	Hogging = 'HOGGING',
	ParkingBlackout = 'PARKING_BLACKOUT',
	ParkingGrace = 'PARKING_GRACE',
	ParkingRegular = 'PARKING_REGULAR',
	Start = 'START'
}

export enum OmniSessionStage {
	ParkingBlackout = 'PARKING_BLACKOUT',
	ParkingGrace = 'PARKING_GRACE',
	ParkingRegular = 'PARKING_REGULAR',
	NotSpecified = 'NOT_SPECIFIED'
}

export enum OmniSessionStatus {
	Pending = 'PENDING',
	Active = 'ACTIVE',
	Stopped = 'STOPPED',
	Completed = 'COMPLETED',
	StartFailure = 'START_FAILURE',
	StopFailure = 'STOP_FAILURE',
	PendingPayment = 'PENDING_PAYMENT'
}

export enum OmniSessionStopReason {
	ChargerTimeout = 'CHARGER_TIMEOUT',
	DuplicateSession = 'DUPLICATE_SESSION',
	InternalError = 'INTERNAL_ERROR',
	PaymentFailure = 'PAYMENT_FAILURE',
	PaymentTimeout = 'PAYMENT_TIMEOUT',
	RemoteStop = 'REMOTE_STOP',
	ReservationNotSupported = 'RESERVATION_NOT_SUPPORTED',
	StartPaymentFailureCurrencyNotSupported = 'START_FAIL_PAY_CURRENCY_NOT_SUPPORTED',
	StartPaymentFailureExceededCreditLimit = 'START_FAIL_PAY_EXCEEDED_CREDIT_LIMIT',
	StartPaymentFailureExpiredCard = 'START_FAIL_PAY_EXPIRED_CARD',
	StartPaymentFailureGenericFailure = 'START_FAIL_PAY_GENERIC_FAILURE',
	StartPaymentFailureInsufficientFunds = 'START_FAIL_PAY_INSUFFICIENT_FUNDS',
	Unknown = 'UNKNOWN_STOP_REASON',
	VehicleManualStop = 'VEHICLE_MANUAL_STOP',
	VoltNetTimeout = 'VOLTNET_TIMEOUT'
}
