import { Link } from 'react-router-dom'

interface CpoLinkProps {
	name: string
	link: string
}

const CpoLink = ({ name, link }: CpoLinkProps): JSX.Element => {
	return (
		<div>
			{name}:{' '}
			<Link target="_blank" className="inline-block" to={link}>
				{`Read ${name} Terms and Conditions here`}
			</Link>
		</div>
	)
}

export default CpoLink
