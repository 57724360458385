import { OmniSession } from 'src/_shared/types/omni'

// FUTURE TO-DO: FE should not be doing this.
interface BasePaymentBreakdown {
	totalKwh: number
	pricePerKwh: number
	energyCost: number
	totalCost: number
}

interface PaymentBreakdownWithoutIdleCost {
	hasIdleCost: false
}

interface PaymentBreakdownWithIdleCost {
	hasIdleCost: true
	totalIdleTime: number
	pricePerIdleTime: number
	idleCost: number
}

interface PaymentBreakdownWithoutPricePerMinCost {
	hasPricePerMinCost: false
}

interface PaymentBreakdownWithPricePerMinCost {
	hasPricePerMinCost: true
	totalChargingTime: number
	pricePerMinTime: number
	pricePerMinCost: number
}

type PaymentBreakdown = BasePaymentBreakdown &
	(PaymentBreakdownWithoutIdleCost | PaymentBreakdownWithIdleCost) &
	(PaymentBreakdownWithoutPricePerMinCost | PaymentBreakdownWithPricePerMinCost)

export const getPaymentBreakdown = (session?: OmniSession): PaymentBreakdown => {
	const formatTotalTimeInHoursToMinutes = (hours = 0): number => {
		return Math.ceil(hours * 60)
	}

	// Energy Costs
	const totalKwh = (() => {
		// For new sessions data that has the new `volumes` field.
		if (session?.volumes?.total_energy_charged_kwh !== undefined) {
			return session.volumes.total_energy_charged_kwh
		}
		// In case of bad/old data, fallback to `kwh` field.
		if (session?.kwh) {
			return session.kwh
		}
		return 0
	})()

	const pricePerKwh = session?.tariff?.price_per_kwh?.incl_vat ?? 0

	const energyCost = ((): number => {
		// For new sessions data that has the new `costs_by_type` field.
		if (session?.costs_by_type?.energy?.incl_vat !== undefined) {
			return session.costs_by_type.energy.incl_vat
		}
		// In case of bad/old data, energy cost is calculated here.
		const rawEnergyCost = totalKwh * Number(pricePerKwh.toFixed(3))

		const maxEnergyCost = session?.tariff?.max_price?.incl_vat ?? 0

		const cappedEnergyCost = rawEnergyCost > maxEnergyCost ? maxEnergyCost : rawEnergyCost
		return cappedEnergyCost
	})()

	const totalCost = session?.total_cost?.incl_vat ?? 0

	// Account for Idle Costs
	const idleCostBreakdown = ((): PaymentBreakdownWithoutIdleCost | PaymentBreakdownWithIdleCost => {
		if (session?.tariff?.price_per_min_parked) {
			const pricePerIdleTime = session.tariff.price_per_min_parked.incl_vat ?? 0

			const totalIdleTime = formatTotalTimeInHoursToMinutes(
				session.volumes?.total_parking_time_charged_hr
			)

			const idleCost = session.costs_by_type?.parking_time?.incl_vat ?? 0

			return {
				hasIdleCost: true,
				totalIdleTime,
				pricePerIdleTime,
				idleCost
			}
		}
		return { hasIdleCost: false }
	})()

	// Account for Price Per Min Costs
	const pricePerMinBreakdown = (():
		| PaymentBreakdownWithoutPricePerMinCost
		| PaymentBreakdownWithPricePerMinCost => {
		if (session?.tariff?.price_per_min) {
			const totalChargingTime = formatTotalTimeInHoursToMinutes(
				session.volumes?.total_time_charged_hr
			)

			const pricePerMinTime = session.tariff.price_per_min.incl_vat ?? 0

			const pricePerMinCost = session.costs_by_type?.time?.incl_vat ?? 0

			return {
				hasPricePerMinCost: true,
				totalChargingTime,
				pricePerMinTime,
				pricePerMinCost
			}
		}
		return {
			hasPricePerMinCost: false
		}
	})()

	return {
		...idleCostBreakdown,
		...pricePerMinBreakdown,
		totalKwh,
		pricePerKwh,
		energyCost,
		totalCost
	}
}
