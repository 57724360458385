import dayjs from 'dayjs'
import { ReactNode, useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Spinner from 'src/_shared/components/Spinner'
import BatteryGradientIcon from 'src/_shared/components/_icons/BatteryGradientIcon'
import CheckCircleFilledIcon from 'src/_shared/components/_icons/CheckCircleFilledIcon'
import CheckCircleGradientIcon from 'src/_shared/components/_icons/CheckCircleGradientIcon'
import HourglassFilledIcon from 'src/_shared/components/_icons/HourglassFilledIcon'
import HourglassGradientIcon from 'src/_shared/components/_icons/HourglassGradientIcon'
import HourglassIcon from 'src/_shared/components/_icons/HourglassIcon'
import { BRAND } from 'src/_shared/constants/env'
import { Brand } from 'src/_shared/enums/env'
import {
	OmniConnectorStatus,
	OmniSessionChargingPeriodDimensionType,
	OmniSessionStage,
	OmniSessionStatus
} from 'src/_shared/enums/omni'
import { OmniSessionChargingPeriod } from 'src/_shared/types/omni'
import { getCurrencyDetails } from 'src/_shared/utils/currency'
import { classNames } from 'src/_shared/utils/elements'

import { BLACKOUT_PERIOD_TIME_FORMAT, TIME_DISPLAY_FORMAT } from '../constants'
import { ChargerScreenViewItemKey } from '../enums'
import { useChargerDetails } from '../hooks/useChargerDetails'
import { useChargerSessionDetails } from '../hooks/useChargerSessionDetails'
import { ChargerScreenCommonViewProps } from '../types'
import { getSessionStateOfCharge } from '../utils'
import StateOfChargeCircle from './StateOfChargeCircle'

type PostChargingViewProps = ChargerScreenCommonViewProps

interface PostChargingViewRenders {
	icon: ReactNode
	headerText: ReactNode
	chargingEndTime: ReactNode
	descriptionText?: ReactNode
	footerText?: ReactNode
}

enum PostChargingViewState {
	Blackout, // State where Idling Fees will not be incurred
	Default,
	Grace, // State before Idling
	Idling
}

const PostChargingView = ({
	routeParams,
	onNext: handleNext
}: PostChargingViewProps): JSX.Element => {
	const intl = useIntl()

	const { connector } = useChargerDetails(routeParams)

	const { session, isUserChargingSession, chargerSessionDetailsQueryStatus } =
		useChargerSessionDetails(routeParams)

	/**
	 * This should be the first period that contains a Parking dimension.
	 */
	const firstPostChargingPeriod = useMemo((): OmniSessionChargingPeriod | null => {
		if (session?.charging_periods && session.charging_periods.length > 0) {
			return (
				session.charging_periods.find((chargingPeriod): boolean => {
					const hasParkingDimension =
						chargingPeriod.dimensions?.some((dimension): boolean => {
							return dimension.type === OmniSessionChargingPeriodDimensionType.ParkingTime
						}) ?? false
					return hasParkingDimension
				}) ?? null
			)
		}
		return null
	}, [session?.charging_periods])

	const latestChargingPeriod = useMemo((): OmniSessionChargingPeriod | null => {
		if (session?.charging_periods && session.charging_periods.length > 0) {
			return session.charging_periods[session.charging_periods.length - 1]
		}
		return null
	}, [session?.charging_periods])

	const postChargingViewState = useMemo((): PostChargingViewState | null => {
		const parkingTimeDimension = latestChargingPeriod?.dimensions?.find((dimension): boolean => {
			return dimension.type === OmniSessionChargingPeriodDimensionType.ParkingTime
		})

		if (parkingTimeDimension) {
			switch (parkingTimeDimension.session_stage) {
				case OmniSessionStage.ParkingBlackout:
					return PostChargingViewState.Blackout
				case OmniSessionStage.ParkingGrace:
					return PostChargingViewState.Grace
				case OmniSessionStage.ParkingRegular:
					return PostChargingViewState.Idling
				case OmniSessionStage.NotSpecified:
					return PostChargingViewState.Default
			}
		}
		// Account for chargers that do not have any idling fees.
		else if (
			chargerSessionDetailsQueryStatus === 'success' &&
			connector?.status === OmniConnectorStatus.Finishing &&
			!session?.tariff?.price_per_min_parked?.incl_vat
		) {
			return PostChargingViewState.Default
		}
		return null
	}, [
		chargerSessionDetailsQueryStatus,
		connector?.status,
		latestChargingPeriod,
		session?.tariff?.price_per_min_parked?.incl_vat
	])

	const { icon, headerText, chargingEndTime, descriptionText, footerText } =
		useMemo((): PostChargingViewRenders => {
			// For View States that are not Idling.
			const getPostChargingIcon = (defaultIcon: JSX.Element): JSX.Element => {
				switch (BRAND) {
					case Brand.Evme: {
						const icon = ((): JSX.Element => {
							switch (postChargingViewState) {
								case PostChargingViewState.Blackout:
								case PostChargingViewState.Grace:
									return (
										<BatteryGradientIcon
											className="w-24"
											lightningFill="#434E64"
											gradientFrom="#CBFF31"
											gradientTo="#80FFCA"
										/>
									)
								default:
									return (
										<CheckCircleGradientIcon
											className="my-4 h-24 w-24"
											gradientFrom="#CBFF31"
											gradientTo="#80FFCA"
										/>
									)
							}
						})()
						return icon
					}
					case Brand.Kineta: {
						const stateOfChargeDimension = getSessionStateOfCharge(session)
						const percentage =
							typeof stateOfChargeDimension?.volume === 'number'
								? stateOfChargeDimension.volume
								: null
						return (
							<StateOfChargeCircle
								variant={percentage !== null ? 'progress' : 'active'}
								centerRender={
									percentage !== null && (
										<>
											<p className="text-[24px] font-semibold leading-7 text-white">
												{percentage}%
											</p>
											<p className="caption-2-medium mb-1 leading-none text-white">
												<FormattedMessage
													id="PostChargingView.TitleStateOfCharge"
													defaultMessage="State of Charge"
												/>
											</p>
										</>
									)
								}
								percentage={percentage}
								isCharging={true}
							/>
						)
					}
					default:
						return defaultIcon
				}
			}

			const chargingEndTime = firstPostChargingPeriod?.start_date_time
				? dayjs(firstPostChargingPeriod.start_date_time).format(TIME_DISPLAY_FORMAT)
				: '-'

			const sessionCompleteHeaderText = intl.formatMessage({
				id: 'PostChargingView.HeaderSessionComplete',
				defaultMessage: 'The Charge Session Is Complete'
			})

			switch (postChargingViewState) {
				case PostChargingViewState.Blackout: {
					const blackoutEndTime = session?.tariff?.idle_blackout_period?.end_time_of_day
						? dayjs(
								session.tariff.idle_blackout_period.end_time_of_day,
								BLACKOUT_PERIOD_TIME_FORMAT
							).format(TIME_DISPLAY_FORMAT)
						: '-'
					return {
						icon: getPostChargingIcon(<BatteryGradientIcon className="w-24" />),
						headerText: sessionCompleteHeaderText,
						chargingEndTime,
						descriptionText: intl.formatMessage(
							{
								id: 'PostChargingView.DescriptonBlackoutGraceLeaveCharger',
								defaultMessage:
									'Please vacate the lot by {idleFeesStartTime} to avoid idle fees and allow other drivers to use the charging station.'
							},
							{
								idleFeesStartTime: blackoutEndTime
							}
						),
						footerText: (
							<span className="text-typography-primary">
								<FormattedMessage
									id="PostChargingView.DescriptionPrefixIdleFeeApplyAt"
									defaultMessage="<b>Idle fees will apply at:</b> <h3>{idleFeesStartTime}</h3>"
									values={{
										b: (chunks): JSX.Element => <span className="body-2-medium">{chunks}</span>,
										h3: (chunks): JSX.Element => <h3 className="text-center">{chunks}</h3>,
										idleFeesStartTime: blackoutEndTime
									}}
								/>
							</span>
						)
					}
				}
				case PostChargingViewState.Grace: {
					const graceEndTime =
						firstPostChargingPeriod?.start_date_time &&
						session?.tariff?.grace_period_seconds !== undefined
							? dayjs(firstPostChargingPeriod.start_date_time)
									.add(session.tariff.grace_period_seconds, 'seconds')
									.format(TIME_DISPLAY_FORMAT)
							: '-'
					return {
						icon: getPostChargingIcon(<BatteryGradientIcon className="w-24" />),
						headerText: sessionCompleteHeaderText,
						chargingEndTime,
						descriptionText: intl.formatMessage(
							{
								id: 'PostChargingView.DescriptonBlackoutGraceLeaveCharger',
								defaultMessage:
									'Please vacate the lot by {idleFeesStartTime} to avoid idle fees and allow other drivers to use the charging station.'
							},
							{
								idleFeesStartTime: graceEndTime
							}
						),
						footerText: (
							<span className="text-typography-primary">
								<FormattedMessage
									id="PostChargingView.DescriptionPrefixIdleFeeApplyAt"
									defaultMessage="<b>Idle fees will apply at:</b> <h3>{idleFeesStartTime}</h3>"
									values={{
										b: (chunks): JSX.Element => <span className="body-2-medium">{chunks}</span>,
										h3: (chunks): JSX.Element => <h3 className="text-center">{chunks}</h3>,
										idleFeesStartTime: graceEndTime
									}}
								/>
							</span>
						)
					}
				}
				case PostChargingViewState.Idling: {
					const { prefix: currencyPrefix } = getCurrencyDetails(session?.tariff?.currency)
					const idleCostRate = (session?.tariff?.price_per_min_parked?.incl_vat ?? 0).toFixed(3)
					return {
						icon: ((): JSX.Element => {
							switch (BRAND) {
								case Brand.Evme: {
									return (
										<HourglassGradientIcon
											className="w-24"
											coverFill="#434E64"
											gradientFrom="#CBFF31"
											gradientTo="#80FFCA"
										/>
									)
								}
								case Brand.Kineta: {
									return (
										<StateOfChargeCircle
											variant="warning"
											centerRender={<HourglassFilledIcon className="h-9 w-9 text-white" />}
										/>
									)
								}

								default:
									return <HourglassIcon className="w-24" />
							}
						})(),
						headerText: intl.formatMessage({
							id: 'PostChargingView.DescriptionIncurringIdlingFees',
							defaultMessage: 'You Are Now Incurring Idle Fees'
						}),
						chargingEndTime,
						descriptionText: intl.formatMessage(
							{
								id: 'PostChargingView.DescriptionIdlingFeesLeaveCharger',
								defaultMessage:
									'You are currently incurring idle fees at {pricePerMin}/min. Please vacate the charging station.'
							},
							{
								pricePerMin: `${currencyPrefix}${idleCostRate}`
							}
						),
						footerText: (
							<span className="text-typography-primary">
								<FormattedMessage
									id="PostChargingView.DescriptionPrefixIdleFeesStartedAt"
									defaultMessage="<b>Idle fees started at:</b> <h3>{idleFeesStartTime}</h3>"
									values={{
										b: (chunks): JSX.Element => <span className="body-2-medium">{chunks}</span>,
										h3: (chunks): JSX.Element => <h3 className="text-center">{chunks}</h3>,
										idleFeesStartTime: latestChargingPeriod?.start_date_time
											? dayjs(latestChargingPeriod.start_date_time).format(TIME_DISPLAY_FORMAT)
											: '-'
									}}
								/>
							</span>
						)
					}
				}
				case PostChargingViewState.Default:
				default: {
					const sessionEndTime =
						// Check that Session is completed so that the correct `end_date_time` is shown.
						session?.status === OmniSessionStatus.Completed && session.end_date_time
							? dayjs(session.end_date_time).format(TIME_DISPLAY_FORMAT)
							: '-'
					return {
						icon: getPostChargingIcon(
							<CheckCircleFilledIcon className="my-4 h-24 w-24 text-success-400" />
						),
						headerText: sessionCompleteHeaderText,
						chargingEndTime: sessionEndTime,
						footerText: (
							<h1 className="text-center text-error-300">
								<FormattedMessage
									id="PostChargingView.DescriptonDefaultLeaveCharger"
									defaultMessage="Please unplug and remove your vehicle."
								/>
							</h1>
						)
					}
				}
			}
		}, [
			firstPostChargingPeriod?.start_date_time,
			intl,
			latestChargingPeriod?.start_date_time,
			postChargingViewState,
			session
		])

	/**
	 * Move to Receipt View when session has been completed.
	 */
	useEffect((): void => {
		if (
			isUserChargingSession &&
			!!connector?.status &&
			[
				OmniConnectorStatus.Available,
				OmniConnectorStatus.Preparing // Account for City Energy, MNL Asia, and Virta.
			].includes(connector.status) &&
			session?.status === OmniSessionStatus.Completed
		) {
			handleNext?.()
		}
	}, [connector?.status, isUserChargingSession, session?.status, handleNext])

	/**
	 * Debugging
	 */
	useEffect((): void => {
		console.debug(
			`[${ChargerScreenViewItemKey.PostChargingView}]\n\n`,
			`Connector Status: ${connector?.status ? connector.status : '-'}\n\n`,
			`Session Status: ${session?.status ? session.status : '-'}\n\n`,
			`Is User's Charging Session: ${isUserChargingSession}\n\n`,
			'Connector Data:\n',
			connector,
			'\n\n',
			'Session Data:\n',
			session
		)
	}, [connector, isUserChargingSession, session])

	if (postChargingViewState === null) {
		return (
			<div className="flex flex-grow flex-col items-center justify-center">
				<Spinner />
			</div>
		)
	}
	return (
		<div className="flex flex-grow flex-col items-center">
			{/* Header */}
			<div className="flex w-full flex-col items-center justify-center space-y-5 py-3">
				<div className="flex items-center">{icon}</div>
				<h1 className="text-center text-typography-primary">{headerText}</h1>
			</div>
			{/* Content */}
			<div className={classNames('flex flex-col items-center', footerText ? 'mb-8' : null)}>
				<p className="body-2-normal mb-5 text-center text-typography-tertiary">
					<FormattedMessage
						id="PostChargingView.SubHeaderPrefixStoppedChargingAt"
						defaultMessage="Vehicle stopped charging at {chargingEndTime}"
						values={{ chargingEndTime }}
					/>
				</p>
				<div className="mb-5 space-y-1 py-3">
					<p className="body-2-normal text-center text-typography-tertiary">
						<FormattedMessage
							id="PostChargingView.SubHeaderTotalCharge"
							defaultMessage="Total Charge"
						/>
					</p>
					<div className="flex flex-row items-end text-typography-primary">
						<h5 className="mr-1.5">{session?.kwh?.toFixed(3) ?? '-'}</h5>
						<h2 className="leading-9">kWh</h2>
					</div>
				</div>
				{descriptionText && (
					<p className="body-2-normal text-center text-typography-tertiary">{descriptionText}</p>
				)}
			</div>
			{/* Footer */}
			{footerText && <div className="mb-8 flex flex-col items-center">{footerText}</div>}
		</div>
	)
}

export default PostChargingView
