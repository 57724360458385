interface DefinitionProps {
	header: string
	body: string
}

const Definition = ({ header, body }: DefinitionProps) => {
	return (
		<div className="flex flex-col items-start justify-start space-y-1">
			<span className="body-3-medium text-typography-primary">{header}</span>
			<span className="body-2-normal text-typography-tertiary">{body}</span>
		</div>
	)
}

export default Definition
