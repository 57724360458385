import { ChangeEvent, useMemo, useState } from 'react'
import { debounce } from 'src/_shared/_old/utils/debounce'
import Input from 'src/_shared/components/Input'
import ScreenContainer from 'src/_shared/components/ScreenContainer'
import SearchIcon from 'src/_shared/components/_icons/SearchIcon'
import { CONTACT_US_BUTTON_LINK, CONTACT_US_BUTTON_TEXT } from 'src/_shared/constants/env'

import Collapse from './components/Collapse'
import ContactCard from './components/ContactCard'
import { CONTACT_US_BUTTON_PREFIX_ICON, SEARCH_INPUT_DEBOUNCE, SECTIONS } from './constants'
import { Section } from './types'

const HelpScreen = (): JSX.Element => {
	const [searchInputValue, setSearchInputValue] = useState<string>('')

	const isContactUsCardConfigured =
		!!CONTACT_US_BUTTON_TEXT && !!CONTACT_US_BUTTON_LINK && !!CONTACT_US_BUTTON_PREFIX_ICON

	const sections = useMemo((): Section[] => {
		if (searchInputValue) {
			return (
				SECTIONS.map(({ questions, ...rest }): Section => {
					return {
						...rest,
						questions: questions.filter(({ question, answer }) => {
							// Check loosely for the search value within the Question and Answer.
							return (
								question.toLowerCase().includes(searchInputValue) ||
								answer.toLowerCase().includes(searchInputValue)
							)
						})
					}
				})
					// Return sections with at least 1 found question.
					.filter(({ questions }): boolean => {
						return questions.length > 0
					})
			)
		}
		return SECTIONS
	}, [searchInputValue])

	const noSearchResultsFound = useMemo((): boolean => {
		return searchInputValue.length > 0 && sections.length === 0
	}, [searchInputValue.length, sections.length])

	const handleSearchInputValueChange = debounce(
		((event: ChangeEvent<HTMLInputElement>): void => {
			setSearchInputValue(event.target.value.toLowerCase().trim())
		}) as (...args: unknown[]) => unknown,
		SEARCH_INPUT_DEBOUNCE
	)

	return (
		<ScreenContainer
			contentViewProps={{
				className: 'px-5 py-6'
			}}
			hideBottomBar
		>
			{/* Search Input */}
			<div className="mb-6 w-full">
				<Input
					placeholder="Search help..."
					startAdornment={<SearchIcon className="h-6 w-6 text-typography-secondary" />}
					onChange={handleSearchInputValueChange}
				/>
			</div>
			{/* Content */}
			<div
				className={
					isContactUsCardConfigured && noSearchResultsFound
						? 'flex h-full w-full flex-col justify-center'
						: 'w-full'
				}
			>
				{/* Header */}
				{!searchInputValue && (
					<div>
						<h1 className="mb-5 text-typography-primary">Frequently Asked Questions</h1>
					</div>
				)}
				{/* Sections */}
				{sections.length > 0 && (
					<div>
						{sections.map(({ sectionName, questions }): JSX.Element => {
							// Note: Do not use `index` as key here to ensure that components
							// will always be treated as distinct on each render.
							const sectionKey = sectionName.replaceAll(' ', '-').toLowerCase()
							return (
								<div
									key={sectionKey}
									className="border-primary-400/30 [&:not(:last-child)]:mb-5 [&:not(:last-child)]:border-b-[1px]"
								>
									{/* Section Title */}
									<div className="mb-3">
										<p className="body-3-semibold text-typography-primary">{sectionName}</p>
									</div>
									{/* Question and Answer */}
									{questions.map(({ question: title, answer, answerElement }): JSX.Element => {
										const description = answerElement ?? answer
										const collapseKey = title.replaceAll(' ', '-').toLowerCase()
										return <Collapse key={collapseKey} title={title} description={description} />
									})}
								</div>
							)
						})}
					</div>
				)}
				{/* Contact Cards */}
				{isContactUsCardConfigured && !searchInputValue && (
					<ContactCard
						buttonText={CONTACT_US_BUTTON_TEXT}
						buttonLink={CONTACT_US_BUTTON_LINK}
						buttonPrefixIcon={CONTACT_US_BUTTON_PREFIX_ICON}
						className="bg-info-100"
					/>
				)}
				{isContactUsCardConfigured && noSearchResultsFound && (
					<ContactCard
						buttonText={CONTACT_US_BUTTON_TEXT}
						buttonLink={CONTACT_US_BUTTON_LINK}
						buttonPrefixIcon={CONTACT_US_BUTTON_PREFIX_ICON}
						className="mb-16" // Offset to visually appear more vertically center.
					/>
				)}
			</div>
		</ScreenContainer>
	)
}

export default HelpScreen
